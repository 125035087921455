import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";
import DevelopmentArchitecture from "../components/includes/developmentArchitecture";

const SecondPage = () => (
  <Layout>
    <SEO title="Our Approach" />
    <div className="contentWrapper">
        <section className="staticContainer innerSection">
            <div className="wrapper">
                <h2>Your End-to-End Partner</h2>
                <p className="subHead">We collaborate with our clients in building design and engineering solutions. We offer two types of engagement models that is flexible and scalable as you grow your business: Project-Based and Team Augmentation.</p>
                <div className="innerBlk">
                    <h3>Project-Based</h3>
                    <div className="botGap1">
                        <img src="//res.cloudinary.com/digital-avenues/image/upload/v1611556777/digitalavenues.com/static/project-based.png" alt="" className="showDesktop" />
                        <img src="//res.cloudinary.com/digital-avenues/image/upload/v1611556777/digitalavenues.com/static/project-based-mobile.png" alt="" className="showMobile" />
                    </div>
                    <h3>Team Augmentation</h3>
                    <div>
                        <img src="//res.cloudinary.com/digital-avenues/image/upload/v1617253484/digitalavenues.com/static/dedicated-teams.png" alt="" className="showDesktop" />
                        <img src="//res.cloudinary.com/digital-avenues/image/upload/v1617253484/digitalavenues.com/static/dedicated-teams-mobile.png" alt="" className="showMobile" />
                    </div>
                </div>
            </div>
        </section>
        <DevelopmentArchitecture></DevelopmentArchitecture>
    </div>
  </Layout>
)

export default SecondPage
